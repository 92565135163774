(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("mobx"), require("@byted-apaas/env-consumer"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "mobx", "@byted-apaas/env-consumer"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("react"), require("mobx"), require("@byted-apaas/env-consumer")) : factory(root["react"], root["mobx"], root["@byted-apaas/env-consumer"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, (__WEBPACK_EXTERNAL_MODULE__8156__, __WEBPACK_EXTERNAL_MODULE__259__, __WEBPACK_EXTERNAL_MODULE__3103__) => {
return 