import { useState, useEffect, forwardRef } from 'react'; 
export const components = {};
export const _version = '2.0'
const enableHMR = process.env.NODE_ENV !== 'production' && !!module.hot
enableHMR && console.log('🔥🔥🔥 Custom component(Web) HMR enabled. 🔥🔥🔥')
const loadedComponents = {}; 
function loadComponentWrapper(getComponent, getMeta, hmr) {
  const { model, component, icon } = getMeta(); 
  const apiName = component.apiName; 
  if (apiName in loadedComponents) {
    return loadedComponents[apiName]; 
  } 
  const getLoader = () => forwardRef(function Loader(props, ref) {
    const [Component, setComponent] = useState(null); 
    const [error, setError] = useState(null)
    useEffect(() => {
      const load = () => getComponent().then(mod => {
        setComponent(() => mod.default); 
      }).catch(e => {
        window.console.error('========= ERROR START =========')
        window.console.error(e)
        window.console.error('=========  ERROR END  =========')
        setError(e); 
      }); 
      load(); 
      return hmr(() => { setError(null); load(); })
    }, []); 
    if (error) {
      return (
        < code style = {{ color: 'red' }
            }> 
          {
            `Load component(${apiName}) failed, you can open console to get error details.`}
          {`${error.name}: ${error.message}`}
        </code>
      );
    }
    if(Component) {
      return (
        <Component ref={ref} {...props}/>
      )
    }
    return null;
  });
  let c = getLoader();
  c._version = '2.0';
  c._component = component
  c._component.icon = icon
  c._model = model
  loadedComponents[apiName] = c;
  return c;
}
Object.defineProperties(components, {
  'hongbao': {
    enumerable: true,
    get() {
      const hmr = () => () => undefined
      const getComponent = async () => await import(
        /* webpackChunkName: "components/hongbao" */
        '../hongbao/index.tsx'
      )
      const getMeta = () => {
        return {
          component: require('../hongbao/index.meta.json'),
          model: {},
          icon: '',
        };
      };
      return loadComponentWrapper(getComponent, getMeta, hmr);
    },
  },
});